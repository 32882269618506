<template>
  <div style="height: calc(100vh - 40px); overflow-y: auto">
    <el-form :inline="true" :model="queryData">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="课程名称：">
              <el-input
                clearable
                v-model="queryData.inputName"
                placeholder="请输入"
                class="w100"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="分类：">
              <el-select
                clearable
                v-model="queryData.catalogId"
                placeholder="分类"
                class="w100"
              >
                <el-option
                  v-for="item in catalogIdList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <span>
            <el-button @click="reset()">重 置</el-button>
            <el-button type="primary" @click="search()">查 询</el-button>
          </span>
        </el-row>
      </div>
    </el-form>
    <!-- 表格 -->
    <el-card
      shadow="never"
      style="overflow-y: scroll; height: calc(100% - 80px)"
    >
      <div slot="header" class="flex" style="align-items: center">
        <span>课程数据</span>
        <div class="flex_1"></div>
<!--        <el-button type="primary" size="mini" @click="addCurriculum()"-->
<!--          >添加-->
<!--        </el-button>-->
        <!-- <el-button type="info" size="medium" @click="addCurriculum()" class="tb-button">新 增</el-button> -->
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table
        :default-sort="{ prop: 'open_date', order: 'descending' }"
        :key="tableKey"
        fit
        :highlight-current-row="true"
        :data="dataList"
      >
        <el-table-column
          v-for="item of defaultHeader"
          :key="item.label"
          :label="item.label"
          :align="item.align ? item.align : 'left'"
          :min-width="item.width ? item.width : 'auto'"
          :sortable="item.sortable ? item.sortable : false"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <template v-if="item.label === '状态'">
              <el-tag :type="stateType[scope.row.status]">
                {{ scope.row.status }}
              </el-tag>
            </template>
            <template v-else-if="item.label === '课程名称'">
              <span class="title-link" @click="skipDetails(scope.row.id)">{{
                scope.row.title
              }}</span>
            </template>
            <template v-else-if="item.label === '操作'">
              <el-button
                style="margin-left: 8px"
                type="text"
                @click="skipDetails(scope.row.id)"
              >
                管理
              </el-button>
<!--              <el-button style="margin-left: 8px"  @click="showMemberModal(scope.row)" type="text"> 学员 </el-button>-->
<!--              <el-button-->
<!--                style="margin-left: 8px"-->
<!--                type="text"-->
<!--                @click="selectMemberModal(scope.row)"-->
<!--              >-->
<!--                分配学员-->
<!--              </el-button>-->
            </template>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>
    <!-- 新增课程 dialog -->
    <add-curriculum
      :show="curriculumDialog"
      v-if="curriculumDialog"
      :editData="editData"
      @close="closeCurriculum()"
      @updateView="updateView"
    />
    <!-- 分配学员 -->
    <select-member
      :show="selectMemberDialog"
      v-if="selectMemberDialog"
      :lessTeacher="seleStuData"
      :courseInfo="courseInfo"
      :entryType="false"
      @editStuData="editStuData"
      @close="closeSelectMemberModal()"
    />
    <!-- 学员列表 -->
    <show-member
      :drawer="showMemberDialog"
      v-if="showMemberDialog"
      :courseInfo="courseInfo"
      @close="closeShowMemberModal()"
    />
  </div>
</template>

<script>
import AddCurriculum from "@/components/TrainCourse/add";
import SelectMember from "@/components/TrainCourse/selectMember";
import ShowMember from "@/components/TrainCourse/member";
import getStateLessonColor from "@/mixins/getStateColor";
import {
  getCourseList,
  editCourse,
  delCourse,
  $getCatalogTree
} from "@/api/research/curriculum";
import {getSysAccountList} from "@/api/system/account";

import TextButton from "@/components/TextButton";
import {batchCourseUser } from "@/api/train";

// 默认展示列
const baseColSetting = [
  {
    label: "课程名称",
    prop: "title",
    state: true,
    align: "center",
    width: "150px",
  },
  {
    label: "分类",
    prop: "catalogTitle",
    state: true,
    align: "center",
    width: "150px",
  },
  {
    label: "操作",
    state: true,
    align: "center",
    width: "150",
  },
];
export default {
  components: {
    AddCurriculum,
    TextButton,
    SelectMember,
    ShowMember,
  },
  mixins: [getStateLessonColor],
  data() {
    return {
      tableKey: 1,
      queryData: {
        inputName: null,
      },
      stateType: {
        未开始: "info",
        进行中: "",
        成功: "success",
        失败: "error",
        警告: "warning",
        已发布: "success",
        未发布: "info",
      },
      catalogIdList:[],
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
      },
      curriculumDialog: false,
      selectMemberDialog: false,
      showMemberDialog: false,
      courseInfo: {},
      seleStuData: [],
      editData: null,
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  created() {
    this.getCourseList();
    this.getCatalogTree()
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    async getCatalogTree() {
      const res = await $getCatalogTree({
        group:'培训'
      });
      this.catalogIdList = res.body;
    },
    // 获取课程列表
    async getCourseList() {
      const res = await getCourseList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        status: '已发布',
        title: this.queryData.inputName,
        catalogId:this.queryData.catalogId,
        type: "培训",
        needCount: true,
      });
      this.dataList = res.body.list;
      this.pagination.total = res.body.total;
    },

    // 添加课程
    addCurriculum() {
      this.curriculumDialog = true;
    },
    closeCurriculum() {
      this.curriculumDialog = false;
      this.editData = null;
    },
    // 分培学员
    selectMemberModal(val) {
      this.courseInfo = val;
      getSysAccountList({
        pageNum: 1,
        pageSize: 99999,
        courseId: val.id,
        merchantId: "",
      }).then((res) => {
        this.seleStuData = res.body.list.map((i) => {
          return {
            ...i,
            userId: i.id,
          };
        });
        this.$nextTick(() => {
          this.selectMemberDialog = true;
        });
      });
    },
    showMemberModal(val) {
      this.showMemberDialog = true;
      this.courseInfo = val;
    },
    closeSelectMemberModal() {
      this.selectMemberDialog = false;
      this.courseInfo = null;
      this.seleStuData = null;
    },
    closeShowMemberModal() {
      this.showMemberDialog = false;
      this.courseInfo = null;
    },
    editStuData(val) {
      batchCourseUser({
        courseId: this.courseInfo.id,
        userIds: val.map((i) => i.id),
      }).then((res) => {
        this.$message.success("分配成功");
        this.closeSelectMemberModal();
      });
    },
    // 编辑
    editCurriculum(row) {
      this.editData = row;
      this.curriculumDialog = true;
    },

    // 重置
    reset() {
      this.queryData = {};
    },

    // 发布课程
    publishCurriculum(row) {
      editCourse({
        id: row.id,
        status: "已发布",
      }).then((res) => {
        if (res.state === "success") {
          window.$msg("发布成功");
          this.getCourseList();
        }
      });
    },

    // 关闭课程
    closeCurriculums(row) {
      this.$confirm("确定关闭当前课程吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          editCourse({
            id: row.id,
            status: "已关闭",
          }).then((res) => {
            if (res.state === "success") {
              window.$msg("关闭成功");
              this.getCourseList();
            }
          });
        })
        .catch(() => {
          return false;
        });
    },

    // 课程归档
    archiveCurriculum(row) {
      editCourse({
        id: row.id,
        status: "已归档",
      }).then((res) => {
        if (res.state === "success") {
          window.$msg("归档成功");
          this.getCourseList();
        }
      });
    },

    // 更新视图
    updateView() {
      this.getCourseList();
    },

    // 分页
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getCourseList();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getCourseList();
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1;
      this.getCourseList();
    },

    // 删除
    delCurriculum(row) {
      this.$confirm("确定删除当前课程吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCourse({
            ids: [row.id],
          }).then((res) => {
            if (res.state === "success") {
              window.$msg("删除成功");
              this.getCourseList();
            }
          });
        })
        .catch(() => {
          return false;
        });
    },

    // 跳转课程详情
    skipDetails(curriculumID) {
      this.$router.push({
        path: "/research/curriculum-details-train",
        query: {
          curriculumID,
          courseId:curriculumID,
        },
      });
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/style/table.scss";
@import "@/style/container.scss";
</style>
